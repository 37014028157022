#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;
  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
  &-mobile > .user-phone {
    font-size: 12px;
  }
}
.mobile-header {
  padding: 16px 12px;
  background: white;
  height: 64px;
  .logo {
    padding: 0;
    height: unset;
  }
  .ant-btn {
    box-shadow: none;
  }
  .header-notification {
    & > * {
      margin-right: 10px;
    }
    .profileImage {
      margin: 0;
    }
  }
}
#nav-bar-dropdown {
  .ant-dropdown {
    top: 72px !important; //to override inline style
    width: 100%;
    box-shadow: 0px 8px 11px 2px #ccc;
  }
  .ant-dropdown-menu {
    border-radius: 0;
    box-shadow: 0px 3px 3px -4px rgb(0 0 0 / 12%), 0 6px 6px 0 rgb(0 0 0 / 8%),
      0 9px 9px 8px rgb(0 0 0 / 5%);
    .ant-dropdown-menu-item {
      padding: 10px 32px;
      font-size: 16px;
    }
  }
}
.small-btn {
  height: 24px;
  padding: 2px 8px;
  span {
    font-size: 12px;
  }
}
.page-header {
  h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #676c77;
  }
  .issue-heading {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 999px) {
  .page-header {
    padding: 12px 16px;
    font-size: 20px;
    font-weight: 500;
    background-color: @grey-2;
    h1 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #676c77;
    }
    .issue-heading {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
    .project-details-title svg {
      width: 20px;
      height: 20px;
    }
  }
  .common-card-wrapper {
    background: white;
    padding: 16px;
  }
  .mobile-card {
    border: 1px solid @border-color;
    padding: 16px;
    border-radius: 16px;
  }
  .mobile-card:not(:last-of-type) {
    margin-bottom: 25px;
  }
  .closing-details {
    order: 2;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: unset;
  }
}
.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: 18px;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
      border: none;
    }
  }
}
.ant-table-tbody > tr > td {
  max-width: 500px;
}
tr.ant-table-placeholder > td.ant-table-cell {
  border-bottom: none;
}
.ant-table tr:last-of-type {
  td {
    border-bottom: none;
  }
}
.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}
.mb-80 {
  margin-bottom: 80px;
}

.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-16 {
  margin-right: 16px;
}

.p-0 {
  padding: 0;
}
.p-16 {
  padding: 16px;
}
.p-12 {
  padding: 12px;
}
.p-20 {
  padding: 20px;
}
.p-5 {
  padding: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.d-grid {
  display: grid;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-12 {
  font-size: 12px;
}
// flex-box related style end
//height classes
.height-auto {
  height: auto;
}
.height-percent-100 {
  height: 100%;
}
.min-height-percent-100 {
  min-height: 100%;
}
//width classes end
//width classes
.height-auto {
  height: auto;
}
.width-auto {
  width: auto;
}
.width-percent-20 {
  width: 20%;
}

.width-600 {
  width: 600px;
}

.width-400 {
  width: 400px;
}
.width-300 {
  width: 300px;
}
.width-200 {
  width: 200px;
}

.max-width-900 {
  max-width: 900px;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}
.width-percent-100 {
  width: 100%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}
//width classes

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style

.form-buttons {
  display: flex;
  justify-content: flex-end;
  & button:not(:last-of-type) {
    margin-right: 10px;
  }

  .cancel-button {
    background: @light-blue;
    border-radius: 40px;
    color: @grey-10;
    border-color: @light-blue;
    border: 1px solid;
  }

  .save-button {
    border: none;
    &.width-percent-100 {
      .ant-btn {
        width: 100%;
      }
    }
  }
}
.issue-form-buttons {
  display: flex;
  justify-content: flex-end;
  font-size: 16;
  font-weight: 500;
  line-height: 24px;
  & button:not(:last-of-type) {
    margin-right: 10px;
  }

  .cancel-button {
    background: @white-color;
    border-radius: 40px;
    color: #202a3f;
    border-color: #cdd5e0;
  }

  .save-button {
    border: none;
    background: #202a3f;
    color: @white-color;
  }
}
.change-password-content {
  display: flex;
  justify-content: center;
}

.change-password-inside-content {
  max-width: 600px;
  width: 100%;
  margin: 16px;
  padding: 20px 20px 20px;
  background-color: @white-color;
  .border-radius(@border-radius-lg);
  .box-shadow(@gx-card-shadow);
  font-size: 14px;
  position: relative;
  z-index: 5;
}

.ant-tag {
  border-radius: 10px;
  border: none;
}
.inactive-tag {
  background: @inactive-tag-background;
  color: @label-color;
}

.active-tag {
  background: @active-tag-color;
  color: @active-tag-text-color;
}

.yellow-tag {
  background: @yellow-tag-color;
  color: @yellow-tag-text-color;
}

.red-tag {
  background: @red-tag-color;
  color: @red-tag-text-color;
}

.search-component {
  border-radius: 40px;
  padding: 4px 8px;
  height: 100%;
  margin-right: 10px;
  input {
    line-height: normal;
  }
}

.ant-table-column-sorters {
  padding: 16px 16px;
}
.table {
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    background: @white-color;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    border: 1px solid @grey-5;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: 1px solid @grey-5;
  }
}

.ant-table .ant-table-tbody .ant-btn {
  height: 32px;
  margin-right: 20px;
}

.header {
  h1 {
    margin-bottom: 0px;
  }
}

.ant-switch-checked {
  background: @header-text-color;
}

.userprofile-divider {
  margin: 5px 0;
  border: 1px dashed;
}

.sample-file {
  color: @primary-color;
}
.text-primary {
  color: @primary-color;
}
.import-divider {
  margin: 10px 0;
  border-color: @black-color;
}

.import-modal {
  // To overwrite antd by default
  width: 570px !important;
  .import-required {
    .ant-row.ant-form-item {
      margin-bottom: 0px;
    }
  }
  ul {
    margin-bottom: 0px;
  }
  .import-additional-info {
    h4 {
      min-width: fit-content;
    }
  }
  .import-error {
    h4 {
      color: red;
    }
    .ant-btn {
      background: @inactive-tag-background;
    }
  }
  .note-error {
    color: red;
  }
}
.text-no-break {
  word-break: keep-all;
  white-space: nowrap;
}
.text-break {
  word-break: break-word;
}
.ant-btn span {
  line-height: normal;
}
.ant-card-bordered {
  border: none;
}
.ant-table-thead > tr > th {
  color: @label-color;
}
.ant-table-thead > tr > th::before {
  display: none;
}
.ant-table-column-sorters {
  padding: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: @light-blue;
  .ant-btn {
    svg {
      fill: white;
    }
  }
}
.row-dark {
  background: @light-blue;
}
.tenant-select-row {
  background: @layout-header-background;
  padding: @layout-header-padding;
  min-height: 56px;
  .filter-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid @inactive-tag-background;
    border-radius: 40px;
    padding-left: 8px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
      border: none;
      padding: 0;
      background: transparent;
      .ant-select-selection-item {
        line-height: 34px;
      }
    }
  }
}
.text-secondary {
  color: @label-color;
}
.text-danger {
  color: @danger-color;
}
.text-success {
  color: @success-color;
}
.text-warning {
  color: rgb(255, 193, 7);
}
.white-space-pre-line {
  white-space: pre-line;
}
.fw-normal {
  font-weight: @font-weight-normal;
}
.fw-medium {
  font-weight: @font-weight-medium;
}
.text-underline {
  text-decoration: underline;
}
.filter-div {
  .ant-select-selection-item {
    line-height: normal;
  }
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.opacity-0 {
  opacity: 0;
}
.infinite-loader {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.8);
}
.infinite-data-container {
  top: 0;
  @media screen and (max-width: 999px) {
    .ant-descriptions-item-label {
      color: @grey-8;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      margin-top: 6px;
    }
    .ant-descriptions-item-content {
      color: @grey-9;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
    .ant-card-body {
      padding: 16px;
    }
  }
}
.mobile-card-wrapper:not(:last-of-type) {
  margin-bottom: 20px;
}
.ant-card-bordered {
  border: 1px solid @border-color;
}
@media screen and (max-width: 999px) {
  .ant-card-bordered {
    border: 1px solid @border-color;
  }
  .ant-card-body {
    padding: 16px;
  }
  .ant-card-bordered:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .filters-search .filter-div {
    margin: 0;
  }
  .ant-btn-round.ant-btn-icon-only {
    padding: 5px;
  }
  .mobile-card-wrapper {
    .ant-card-body {
      padding: 0;
      width: 100%;
    }
    .instruction-tab-details-summary {
      &.ant-card {
        border-radius: 0;
        border-width: 1px 0;
      }
      margin-bottom: 24px;
    }
    .issue-activity-mobile {
      padding: 16px;
      height: auto;
      flex: 1;
      overflow: visible;
      &.raised-issues {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .map-input {
    width: 230px;
  }
  .search-component {
    svg {
      fill: @label-color;
    }
  }
}
.ant-form-item-has-error .ant-input-number.ant-input-number-disabled,
.ant-form-item-has-error .ant-picker.ant-input-number-disabled {
  background-color: @grey-3;
  &:hover {
    background-color: @grey-3;
  }
}
.common-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 250px);
  min-height: 400px;
}
.ant-table-content {
  overflow-y: auto;
  border: 1px solid #e5e8ed;
  border-radius: 8px;
}
.ant-btn-icon-only {
  box-shadow: none;
}
.ant-form-item-label label {
  width: 100%;
}
.image-preview-wrapper {
  @media screen and (min-width: 999px) and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
  }
  &.issue-images {
    flex-direction: row;
    .extra-count {
      margin: 0 0 0 5px;
    }
  }
  .ant-image-img {
    border-radius: 4px;
  }
  & div:not(:first-child) {
    &.ant-space-item {
      margin-right: 0 !important; //to override inline style of antd
    }
    .ant-image {
      display: none;
    }
  }
  gap: 0px !important; //to override inline style of antd
}
.card-header:not(.no-actions) {
  padding-right: 35px;
}
.zoom-controls {
  button.ant-btn {
    border-radius: 50%;
    background-color: #f2f4f7;
    padding-top: 8px;
  }
}
.whitespace-wrap {
  white-space: normal;
}
.grace-bar {
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: crimson;
  justify-content: center;
}
.grace-bar-text {
  align-self: center;
  color: white;
  text-align: center;
}
.ant-btn[disabled].comment-button {
  color: white;
  background: rgba(166, 170, 178);
  border: none;
  &:hover {
    color: white;
    background: rgba(166, 170, 178);
    border: none;
  }
  border: none;
}
.has-select-all {
  .ant-select-selector {
    max-height: 200px;
    overflow: auto;
  }
}
.ant-table-pagination.ant-pagination {
  margin: 24px 0 0;
}
.ant-tabs-nav {
  .ant-tabs-tab:not(:last-of-type) {
    margin: 0 4px 0 0;
  }
}
.ant-modal-content {
  .ant-modal-title {
    color: @header-text-color;
  }
  .ant-modal-body {
    color: @grey-10;
  }
}
.audio-player-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: 999px) and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
  }
  &.issue-audio {
    flex-direction: row;
    .extra-count {
      margin: 0 0 0 5px;
    }
  }
}
.extra-count {
  margin-left: 5px;
  background: @grey-4;
  border-radius: 6px;
  padding: 6px;
  font-weight: 400;
  @media screen and (min-width: 999px) and (max-width: 1350px) {
    margin: 5px 0px 0px 0px;
  }
}
.audio-player {
  height: 30px;
  width: 100%;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 40px;
}
.ant-popover-disabled-compatible-wrapper {
  .ant-btn {
    border: none;
  }
}
.ant-form-item-has-error .ant-upload.ant-upload-select-picture-card {
  border-color: @danger-color;
  margin-bottom: 0;
}
.expand-text.ant-typography {
  margin-bottom: 0;
}
.ant-typography,
.ant-typography-ellipsis,
.expand-text {
  white-space: pre-wrap;
  word-break: break-word;
  a {
    display: block;
    width: 100%;
  }
}
.collapsible-filter-wrapper {
  &.issue-filters {
    max-width: calc(100vw - 30%);
  }
  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-col:nth-of-type(2n) {
    padding: 0;
  }
  .ant-col:nth-of-type(2n + 1) {
    padding-left: 0;
  }
  .ant-select-selection-overflow {
    flex: none;
    flex-wrap: nowrap;
  }
  .ant-select-multiple .ant-select-selection-item-content {
    color: #202a3f;
  }
}
.filter-btn.ant-btn {
  border: none;
  padding: 0;
  background-color: @text-color;
  height: 30px;
  min-width: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.filter-btn-close.ant-btn {
  padding: 0;
  background-color: @white-color;
  height: 30px;
  min-width: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e8ed;
  svg {
    path {
      fill: @text-color;
    }
  }
}
//CUSTOM STYLE CLASSES END;
.alert-div {
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
}
.todo-list {
  .ant-empty {
    display: flex;
    height: calc(100vh - 300px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
a:focus {
  text-decoration: underline;
}
.ant-menu,
.ant-dropdown-menu {
  a,
  a:focus {
    text-decoration: none;
    background-color: transparent;
  }
}
.h1-typography {
  color: @header-text-color;
  font-weight: 500;
  line-height: 1.2;
  font-size: 22px;
}
.todo-count {
  background-color: @primary-color;
  margin-left: 5px;
  color: @white-color;
  line-height: 12px;
  .ant-avatar-string {
    font-size: 10px;
    font-weight: 500;
  }
}
.min-width-200 {
  min-width: 200px;
}
.wrapper-404 {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-img {
    margin-bottom: 30px;
  }
  .error-msg {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 6px;
  }
  .home-link {
    font-size: 24px;
  }
}
.focused-filter.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: #ff784f;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
  }
}
.clear-icon-scale {
  .ant-select-clear {
    .anticon {
      transform: scale(1.4);
    }
  }
}
.status-tag {
  font-weight: 500;
  border: solid 1px;
}
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.logout-button.ant-btn {
  background: #202a3f;
  border-color: #202a3f;
  color: @white-color;
  font-size: 16px;
  font-weight: 500;
  svg {
    fill: @white-color;
  }
  &.ant-btn:hover,
  &.ant-btn:focus {
    background: #202a3f;
    color: @white-color;
    border: 1px solid #202a3f;
  }
}

.mobile-layout {
  flex: 1 1;
}
.ant-select-clear {
  transform: scale(1.3);
}
.ant-form {
  input[type='file'] {
    display: unset;
    color: transparent;
  }
  label.file-upload {
    font-size: 16px;
  }
}
.file-upload {
  border: 1px solid #d9d9d9;
  border-radius: 36px;
  padding: 7.9px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
}
.uploaded-images {
  max-height: 130px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  img {
    padding: 8px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
  }
}
.upload-button {
  color: #676c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ant-upload {
  width: 100%;
}
.response-images {
  position: relative;
  height: 65px;
  width: 65px;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin: 0 5px 5px 0;
  &:hover {
    .delete-response-image-button {
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .ant-image-mask {
    display: none;
  }
  .ant-image {
    width: 100%;
  }
}

.delete-response-image-button {
  width: calc(100% - 16px);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: calc(100% - 16px);
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  svg {
    fill: @white-color;
  }
}

.approver-info {
  &.stage-summary {
    top: 120px !important; //due to inline property important is given
  }
  .ant-popover-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: @text-color;
    padding: 16px 16px 0;
    border: none;
  }
  .approvers-card {
    border: none;
    .ant-card-body {
      width: 100%;
      padding: 0;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-content {
    width: 350px;
  }
  .ant-popover-inner {
    border-radius: 12px;
  }
  .ant-popover-inner-content {
    padding: 16px;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;
    .approver-divider {
      margin: 12px 0;
    }
  }
  @media screen and(max-width:500px) {
    padding: 0 5px;
    .ant-popover-content {
      width: 310px;
    }
  }
}
#upgrade-modal {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: @label-color;
    margin-bottom: 10px;
  }
  .ant-modal-body {
    padding: 24px;
    .form-buttons {
      padding: 0;
      .ant-btn {
        margin: 0;
        span {
          margin: 0;
        }
      }
    }
  }
  .ant-modal-close-x {
    svg {
      path {
        fill: @text-color;
      }
    }
  }
}
.double-tick-icon {
  fill: #065f46;
}
.timezone-div {
  font-size: 10px;
  color: #676c77;
  font-weight: 400;
  svg {
    height: 16px;
    width: 16px;
    min-width: unset;
    margin-right: 5px;
    g {
      fill: #676c77;
    }
  }
}
